import request from '@/utils/request'

export function getProblemList(params) {
    return request({
        url: '/problem/page',
        method: 'GET',
        params,
    })
}

export function getProblemDetail(params) {
    return request({
        url: '/problem/getById',
        method: 'GET',
        params,
    })
}

export function createProblem (data) {
    return request({
        url: data.id ? `/problem/update/${data.id}` : `/problem/create`,
        method: 'POST',
        data
    })
}

export function deleteProblem(data) {
    return request({
        url: '/problem/delete',
        method: 'POST',
        data,
    })
}

export function leaveAMessage(data) {
    return request({
        url: '/problem/createMessage',
        method: 'POST',
        data,
    })
}

export function getTimeoutList(params) {
    return request({
        url: '/problem/listTimeoutEvents',
        method: 'GET',
        params,
    })
}

export function saveTimeout(data) {
    return request({
        url: '/problem/addTimeoutEvent',
        method: 'POST',
        data,
    })
}

export function updateTimeout(data) {
    return request({
        url: '/problem/updateTimeoutEvent',
        method: 'POST',
        data,
    })
}

export function deleteTimeout(data) {
    return request({
        url: '/problem/deleteTimeoutEvent',
        method: 'POST',
        data,
    })
}










